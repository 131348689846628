import "./bootstrap-config";
import "./font-awesome-config";
import "./theme";
import "./youtube_vimeo";
import "../styles/main.scss";

// Particles.js
import Particles from "particles.js";
let particleSections = document.getElementsByClassName("particle-background");
for (let i = 0; i < particleSections.length; i++) {
  let configFileName = particleSections[i].dataset.configFile,
    themeName = particleSections[i].dataset.name;
  particlesJS.load(
    particleSections[i].id,
    "/wp-content/themes/" + themeName + "/particles_configs/" + configFileName,
    function () {}
  );
}

// Uncomment to add Animate on Scroll library
// import AOS from "aos";
// import "aos/dist/aos.css";
// AOS.init();

// Uncomment to add Swiper carousel library
import "./swiper-config";
import { config } from "@fortawesome/fontawesome-svg-core";

// Uncomment to add Masonry Layout library
// import Masonry from "masonry-layout";

// Uncomment to add Fancybox light box library
// import { Fancybox } from "@fancyapps/ui";
// import "@fancyapps/ui/dist/fancybox/fancybox.css";
// Fancybox.bind("[data-fancybox]", {
//     // Your custom options
//   });
