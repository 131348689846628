jQuery(document).ready(function () {
  let player;

  // Function to load the YouTube API script dynamically
  function loadYouTubeAPI(callback) {
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    tag.onload = callback; // Calls the function after the script loads
    document.head.appendChild(tag);
  }

  // Set video size on browser resize
  window.addEventListener("resize", () => {
    let iframeVideoSections = document.querySelectorAll(
      ".iframe_background_video_wrapper"
    );
    for (let i = 0; i < iframeVideoSections.length; i++) {
      calculateVideoSize(iframeVideoSections[i]);
    }
  });

  let iframeVideoSections = document.querySelectorAll(
    ".iframe_background_video_wrapper"
  );
  for (let i = 0; i < iframeVideoSections.length; i++) {
    calculateVideoSize(iframeVideoSections[i]);
  }

  // Function to initialize the YouTube player
  function createYouTubePlayer() {
    let youtubeSections = document.querySelectorAll(
      ".youtube_video_background_wrapper"
    );
    for (let i = 0; i < youtubeSections.length; i++) {
      // Set video size on page load
      calculateVideoSize(youtubeSections[i]);

      const videoId = youtubeSections[i].children[0].dataset["videoId"];
      const videoElementId = youtubeSections[i].children[0].id;
      player = new YT.Player(videoElementId, {
        videoId: videoId,
        playerVars: {
          autoplay: 1,
          controls: 0,
          showinfo: 0,
          modestbranding: 1,
          loop: 1,
          fs: 0,
          cc_load_policy: 0,
          iv_load_policy: 3,
          autohide: 1,
          playlist: videoId, // Required for loop
          mute: 1,
        },
        events: {
          onReady: onPlayerReady,
        },
      });
    }
  }

  function calculateVideoSize(videoSection) {
    let videoSectionHeight = videoSection.offsetHeight;
    let videoSectionWidth = videoSection.offsetWidth;
    if (videoSectionHeight > (videoSectionWidth / 16) * 9) {
      // iframe needs to be made wider to make video full height
      let calculatedWidth = (videoSectionHeight / 9) * 16;
      videoSection.children[0].style.width = calculatedWidth + "px";
      videoSection.children[0].style.height = "calc(100% + 5px)";
    } else {
      // iframe needs to be made taller to make video full width
      let calculatedHeight = (videoSectionWidth / 16) * 10 + 5;
      videoSection.children[0].style.height = calculatedHeight + "px";
      videoSection.children[0].style.width = "100%";
    }
  }

  // Assign `onYouTubeIframeAPIReady` to the global scope
  window.onYouTubeIframeAPIReady = createYouTubePlayer;

  // Load the API and wait for it to be ready before creating the player
  loadYouTubeAPI(() => {
    if (window.YT && YT.Player) {
      createYouTubePlayer();
    }
  });

  // Function to play the video when the player is ready
  function onPlayerReady(event) {
    event.target.playVideo();
  }
});
